import "./SearchPage.scss";
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage, IonSpinner, IonToolbar, isPlatform
} from "@ionic/react";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {SearchResultContentItem} from "../../utils/ApiTypes";
import AnalyticsService from "../../misc/AnalyticsService";
import {useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SearchCarouselsComponent from "./SearchCarouselsComponent";
import { SearchBar, sanitizeSearchInput } from '../../components/SearchBar/SearchBar';
import { ResponsiveNavigationMenu } from "../../components/Navigation/ResponsiveNavigationMenu";
import ContentGrid from "../../components/ContentGrid/ContentGrid";


type SearchPageRouteParams = {
    query: string
};

export const SearchPage: React.FC = () => {
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentSearchString, setCurrentSearchString] = useState<string | null>(null);
    const [content, setContent] = useState<SearchResultContentItem[] | null>(null);
    const { handleGeneralError, currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const params = useParams<SearchPageRouteParams>();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if(isComponentVisible){
            setIsLoading(false);
            if(params.query){
                searchContent(sanitizeSearchInput(params.query));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, params.query]);

    
    async function trackSearchString(keyword: string) {
        await AnalyticsService.trackUserAction("search_content", location.pathname, {keyword});
    }

    
    const searchContent = (searchString: string | null) => {
        if(searchString){
            setCurrentSearchString(sanitizeSearchInput(searchString));
            const device = isPlatform("ios") ? "ios" : isPlatform("android") ? "android" : "other";
            setIsLoading(true);
            JourneyApiClient.searchByTitle(searchString, device).then(list => {
                trackSearchString(searchString);
                setIsLoading(false);
                setContent(list);
            }).catch(error => {
                handleGeneralError(error, undefined, { 
                    contextName: "Could not fetch videos", 
                    contextData: {
                        userId: currentUser?.id,
                        company: currentUser?.company,
                        searchString: searchString,
                    }
                });
                setIsLoading(false);
            });
        }
    }


   

    return(
        <IonPage className={`search-page`}>

            <ResponsiveNavigationMenu title={"Search"} />
            
            <IonToolbar className="search-page-toolbar">
                <SearchBar 
                    onNewSearchQuery={searchContent} 
                    isSearchLoading={isLoading}
                    onClearSearchQuery={() => setCurrentSearchString(null)}
                />
            </IonToolbar>
            <IonContent className="search-page-content">
                {isLoading ?
                    <div className="search-page-loading-indicator-container">
                        <IonSpinner className="search-page-loading-indicator" name={"crescent"}/>
                    </div>
                    :
                    ((currentSearchString && !!content?.length) ?
                        <div className="search-container-grid-wrapper industry-targeting-grid-wrapper">
                            <ContentGrid content={content} className={"search-page-content-grid-container"} showPlaylistTitle={true}/> 
                        </div>
                        :
                        <div>
                            <div className="results-text caption">
                                {
                                    currentSearchString ?
                                    `${t("No results for")} ${currentSearchString}` :
                                    <SearchCarouselsComponent />
                                }
                            </div>
                        </div>
                    )
                }
            </IonContent>
        </IonPage>
    )
};

