import React, { useEffect, useContext, useState } from 'react';
import { App as CapApp, URLOpenListenerEvent } from '@capacitor/app';
import AnalyticsService from "../../misc/AnalyticsService";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { reloadWithoutParams, removeEndingForwardSlash } from "../../utils/utils";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import ClientConfig from "../../utils/ClientConfig";
import { isPlatform } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import DemoResetService from '../../misc/DemoResetService';
import { useAuth0 } from "@auth0/auth0-react";

export const AppUrlListener: React.FC = () => {
    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const location = useLocation();
    const [currentPathName, setCurrentPathName] = useState<string | null>(null);
    const {
        currentUser,
        isMobileApp,
        initializeCurrentUser,
        handleGeneralError,
        setIsMindfulBreakModalOpen,
        setIsCommunicationsOptInModalOpen,
        setSubscribeToDailyJourney,
        setIsAppLoaded,
        isAppLoaded,
        setIsResetInProgress,
        changeLanguage,
        setNeedProfileRefresh,
    } = useContext(ApplicationContext);


    async function browserBootSequence() {
        const queryParams = new URLSearchParams(window.location.search);
        let token = queryParams.get("token");
        let code = queryParams.get("code");
        let state = queryParams.get("state");
        let impersonationToken = queryParams.get("i");

        if(code && !state){ // IS WORKOS
            await JourneyApiClient.authUserByWorkOsCode(code);
            reloadWithoutParams();
        }
        else if(token){ // is journeyauthtoken
            await JourneyApiClient.authUserByToken(token);
            reloadWithoutParams();
        }
        else if(impersonationToken){
            await JourneyApiClient.authUserByImpersonationToken(impersonationToken);
            reloadWithoutParams();
        }
        // Check if launch url contains a token, if it does, remove it from the URL, log the user in
        // and refresh the app so, we can start from a more clean state

        let launchQueryParams = new URLSearchParams(location.search);

        /*
         * About setRedirectUrl():
         *
         * If a logged out user tries to navigate to a page that requires authentication (for example /playlist/{id})
         * the route will saved via `setRedirectUrl()` and the user will be redirected to the root path `/`.
         *
         * Upon log in, the app will check if we had set a redirect URL and navigate there.
         */
        if (!currentUser && !location.pathname.includes("/auth/sso") && !location.pathname.includes("/register") && location.pathname !== "/") {
            JourneyApiClient.setRedirectUrl(location.pathname);
        }

        setGlobalAnalyticsProperties();
        await initializeCurrentUser();

        await trackAppLaunch(launchQueryParams);

        const demoReset = launchQueryParams.get("demoReset");

        if (demoReset) {
            setIsResetInProgress(true);
            await DemoResetService.reset(currentUser!, changeLanguage);

            setIsResetInProgress(false);
            setNeedProfileRefresh(true);
            reloadWithoutParams();
        }

        setIsAppLoaded(true);

        const openMindfulBreakModalParam = launchQueryParams.get("mindfulbreak");
        if (openMindfulBreakModalParam && openMindfulBreakModalParam === 'open') {
            setIsMindfulBreakModalOpen(true);
        }

        const openCommunicationsOptInModalParam = launchQueryParams.get("communications-opt-in");
        if (openCommunicationsOptInModalParam === 'true') {
            setIsCommunicationsOptInModalOpen(true);
        }

        const subscribeToDailyJourney = launchQueryParams.get("subscribe-to-daily-journey");

        if (subscribeToDailyJourney) {
            setSubscribeToDailyJourney(true);
        }
    }


    /**
     * This boot-up sequence only runs on native mobile apps.
     *
     * Mobile apps don't natively open via a URL being clicked on or typed into a browser URL bar.
     * Instead, they are opened by a regular launch event from the mobile OS or via a magic link triggered
     * by the mobile OS and always starts at the root URL path. Because of this, we manually have to do any
     * initial URL handling and navigation.
     *
     * Note that on mobile, the token is only available via the launchURL and it is not displayed/visible in an
     * url bar, thus there is no need to clear it.
     *
     * What this does:
     * - Authentication during clean-launch and background-launch
     * - Tracks analytics logged-in/out state during clean-launch and background-launch
     * - Deep-link during clean-launch and background-launch
     */
    async function mobileBootUpSequence(launchUrlString: string) {
        // Example launchUrl:  https://app2.journey.live/watch/1102?param=foo&token=bar
        // Example launchQueryParamsString: param=foo&token=bar
        // Example launchPathName: /watch/1102 (note this does not include the query params)
        let redirectDestination = null;
        let launchPathNameString = "";
        let launchSearchParams = "";

        try {
            const launchUrl = new URL(launchUrlString);
            launchSearchParams = launchUrl.search ?? "";
            launchPathNameString = launchUrl.pathname;
        } catch (e) {
            // Ignore empty or mal-formatted URLs
        }

        // Check if launch url contains a token, process it and remove it from the query string

        // We now figure out if we need to navigate to a new path based on the current path and the launch url
        const currentPathName = removeEndingForwardSlash(location.pathname);
        const launchPathName = removeEndingForwardSlash(launchPathNameString);

        if (launchPathName && currentPathName !== launchPathName) {
            redirectDestination = launchPathName;
        }

        let launchQueryParams = new URLSearchParams(launchSearchParams);

        let token = launchQueryParams.get("token");
        let code = launchQueryParams.get("code");
        let state = launchQueryParams.get("state");
        let impersonationToken = launchQueryParams.get("i");

        if(code && !state){ // IS WORKOS
            await JourneyApiClient.authUserByWorkOsCode(code);
        }
        else if(token){ // is journeyauthtoken
            await JourneyApiClient.authUserByToken(token);
        }
        else if(impersonationToken){
            await JourneyApiClient.authUserByImpersonationToken(impersonationToken);
        }

        setGlobalAnalyticsProperties();
        await initializeCurrentUser();
        await trackAppLaunch(launchQueryParams);
        setIsAppLoaded(true);

        const openMindfulBreakModalParam = launchQueryParams.get("mindfulbreak");
        if (openMindfulBreakModalParam && openMindfulBreakModalParam === 'open') {
            setIsMindfulBreakModalOpen(true);
        }

        const openCommunicationsOptInModalParam = launchQueryParams.get("communications-opt-in");
        if (openCommunicationsOptInModalParam === 'true') {
            setIsCommunicationsOptInModalOpen(true);
        }

        const subscribeToDailyJourney = launchQueryParams.get("subscribe-to-daily-journey");
        if (subscribeToDailyJourney) {
            setSubscribeToDailyJourney(true);
        }

        if (redirectDestination) {
            history.push(redirectDestination);
        }
    }

    async function trackAppLaunch(params: URLSearchParams) {
        let eventProperties = {
            utm_source: params.get("utm_source"),
            utm_medium: params.get("utm_medium"),
            utm_campaign: params.get("utm_campaign"),
            utm_term: params.get("utm_term"),
            utm_content: params.get("utm_content"),
            page_referrer: document.referrer
        }
        await AnalyticsService.trackUserAction("app_launch", null, eventProperties);
    }

    function setGlobalAnalyticsProperties() {
        const operatingSystem = isPlatform("ios") ? "ios" :
            isPlatform("android") ? "android" : isPlatform("desktop") ? "web" : "other";

        AnalyticsService.setGlobalMetadata({
            operating_system: operatingSystem,
            is_native_mobile_app: isMobileApp,
            version: ClientConfig.appVersion,
            device_agent: navigator.userAgent,
        });
    }

    function urlWithoutParam(paramName: string): string {
        const queryParams = new URLSearchParams(window.location.search);

        queryParams.delete(paramName);
        let paramsString = queryParams.toString();
        if (paramsString) {
            paramsString = "?" + paramsString;
        }
        return `${window.location.pathname}${paramsString}`;
    }

    useEffect(() => {
        // We include `isAppLoaded` in the conditions to make sure that when we track the navigation event
        // we have already identified/initialized the current user if they are logged in
        if (location.pathname !== currentPathName && isAppLoaded) {
            AnalyticsService.trackPageNavigation(location.pathname, currentPathName);
            setCurrentPathName(location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, isAppLoaded]);


    useEffect(() => {

        (async function () {

            if (isMobileApp) {
                // Handle the initial mobile app launch.
                // There might or might not be a launchURL if the app was opened via a deep link or not.
                try {
                    const launchUrl = await CapApp.getLaunchUrl();
                    await mobileBootUpSequence(launchUrl?.url ?? "");
                } catch (e) {
                    handleGeneralError(e, undefined, { 
                        contextName: "Problems during app initialization.", 
                        contextData: {
                            userId: currentUser?.id,
                            company: currentUser?.company,
                        } 
                    });
                }

                // handle subsequent deep-link app opens
                CapApp.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
                    try {
                        await mobileBootUpSequence(event.url);
                    } catch (e) {
                        handleGeneralError(e, undefined, { 
                            contextName: "Problems during app initialization.", 
                            contextData: {
                                userId: currentUser?.id,
                                company: currentUser?.company,
                            } 
                        });
                    }
                });

            } else {
                browserBootSequence()
                    .catch(e => 
                        handleGeneralError(e, undefined, { 
                            contextName: "Problems during app initialization.", 
                            contextData: {
                                userId: currentUser?.id,
                                company: currentUser?.company,
                            } 
                        })
                    );
            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
